export var TemplateTypes;
(function (TemplateTypes) {
    TemplateTypes[TemplateTypes["legal"] = 1] = "legal";
    TemplateTypes[TemplateTypes["public"] = 2] = "public";
})(TemplateTypes || (TemplateTypes = {}));
/**
 * Retreive a unique ID that changes when we update the template styles.
 * @param template template we need to retreive the ID from
 * @returns unique ID that can be used to bust caches in the ID server
 */
export const getAdTemplateCacheID = (template) => {
    const { styles } = template.data();
    return (styles === null || styles === void 0 ? void 0 : styles.id) || template.id;
};
/**
 * Default styles to apply when a template does not specify. These were
 * extracted from the "standard_noheader" template and there is nothing
 * magical about them, they can be changed freely to improve the UI/UX.
 */
export const DEFAULT_TEMPLATE_STYLES = {
    id: 'default',
    font: 'Helvetica',
    fontSize: 8,
    leading: 9,
    tracking: 0,
    firstLineIndent: 0,
    justification: 'LeftJustified',
    pointsPerInch: 72,
    pageHeight: 1520.64,
    pageWidth: 836.64,
    columnCount: 6,
    columnGutter: 12.024,
    hyphenation: true,
    textInset: 0,
    borderWidth: 0,
    titleParagraphStyle: {}
};
