var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { SnapshotModel, getModelFromSnapshot } from '..';
import { Collections } from '../../constants';
import { getColumnInches, getDistributedLineItems } from '../../pricing';
import { NewspaperOrderStatus, validateCompleteNewspaperOrder } from '../../types/newspaperOrder';
import { OrderStatus, isAdvertiserOrder, isIndividualOrder, isPublisherAsAdvertiserOrder } from '../../types/order';
import { getErrorReporter } from '../../utils/errors';
import { getOrThrow } from '../../utils/refs';
import { NewspaperOrderModel } from './newspaperOrderModel';
import { OrganizationModel } from './organizationModel';
import { getDateForDateStringInTimezone } from '../../utils/dates';
import { getErrors, wrapError, wrapSuccess } from '../../types/responses';
import { OrganizationType, Product } from '../../enums';
import { ColumnService } from '../../services/directory';
import { NewspaperOrderService } from '../../services/newspaperOrderService';
import { InvoiceService } from '../../services/invoiceService';
import { ObituaryService } from '../../services/obituaryService';
import { ClassifiedService } from '../../services/classifiedService';
import { isClassifiedModel, isObituaryModel } from './adModel';
import { InternalServerError, NotFoundError, wrapErrorAsColumnError } from '../../errors/ColumnErrors';
import { safeGetOrThrow } from '../../safeWrappers';
import { OrderDetailService } from '../../services/orderDetailService';
import { ORDER_EDITED } from '../../types/events';
import { EventService } from '../../services/eventService';
export class OrderModel extends SnapshotModel {
    constructor() {
        super(...arguments);
        this.invoiceService = new InvoiceService(this.ctx);
        this.obituaryService = new ObituaryService(this.ctx);
        this.classifiedService = new ClassifiedService(this.ctx);
        this.orderDetailService = new OrderDetailService(this.ctx);
        this.newspaperOrderService = new NewspaperOrderService(this.ctx);
        this.advertiserOrganization = null;
        this.eventService = new EventService(this.ctx);
    }
    get type() {
        return Collections.orders;
    }
    get adService() {
        return this.isClassifiedOrder
            ? this.classifiedService
            : this.obituaryService;
    }
    // TODO (goodpaul): Move existing calls to advertiserOrganization to this method
    getAdvertiserOrganization() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.advertiserOrganization &&
                (isAdvertiserOrder(this.modelData) ||
                    isPublisherAsAdvertiserOrder(this.modelData))) {
                const { response: advertiserOrganizationSnap, error: getError } = yield safeGetOrThrow(this.modelData.advertiserOrganization);
                if (getError) {
                    return wrapErrorAsColumnError(getError, NotFoundError);
                }
                this.advertiserOrganization = getModelFromSnapshot(OrganizationModel, this.ctx, advertiserOrganizationSnap);
            }
            return wrapSuccess(this.advertiserOrganization);
        });
    }
    getOrderVersion(specifiedVersion) {
        const version = specifiedVersion !== null && specifiedVersion !== void 0 ? specifiedVersion : this.modelData.activeVersion;
        if (!version) {
            const error = new InternalServerError('Order has no active version');
            getErrorReporter().logAndCaptureCriticalError(ColumnService.OBITS, error, 'Failed to get default order version', {
                orderId: this.id
            });
            return wrapError(error);
        }
        return wrapSuccess(version);
    }
    getNewspaperOrdersQuery({ includeDeleted = false, specifiedVersion = null } = {}) {
        const { response: version, error: versionError } = this.getOrderVersion(specifiedVersion);
        if (versionError) {
            return wrapError(versionError);
        }
        let newspaperOrderQuery = this.ctx
            .orderNewspaperOrdersRef(this.ref)
            .where('orderVersion', '==', version);
        if (!includeDeleted) {
            newspaperOrderQuery = newspaperOrderQuery
                .orderBy('status', 'asc')
                .where('status', '!=', NewspaperOrderStatus.DELETED);
        }
        return wrapSuccess(newspaperOrderQuery);
    }
    getNewspaperOrders({ includeDeleted = false, specifiedVersion = null } = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: newspaperOrderQuery, error: queryError } = this.getNewspaperOrdersQuery({
                includeDeleted,
                specifiedVersion
            });
            if (queryError) {
                throw queryError;
            }
            const newspaperOrderSnaps = yield newspaperOrderQuery.get();
            const newspaperOrders = newspaperOrderSnaps.docs.map(doc => getModelFromSnapshot(NewspaperOrderModel, this.ctx, doc));
            return newspaperOrders;
        });
    }
    updateNewspaperOrders(newspaperOrders, version) {
        return __awaiter(this, void 0, void 0, function* () {
            const newspaperOrderModels = yield this.getNewspaperOrders({
                includeDeleted: true,
                specifiedVersion: version
            });
            const updatePromises = newspaperOrders === null || newspaperOrders === void 0 ? void 0 : newspaperOrders.map((newspaperOrder) => __awaiter(this, void 0, void 0, function* () {
                var _a, _b;
                const newspaperOrderModel = newspaperOrderModels.find(model => {
                    var _a;
                    return model.modelData.newspaper.id === ((_a = newspaperOrder === null || newspaperOrder === void 0 ? void 0 : newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id) &&
                        model.modelData.publishingMedium === newspaperOrder.publishingMedium;
                });
                if (newspaperOrderModel) {
                    if (newspaperOrderModel.modelData.status !== NewspaperOrderStatus.DRAFT) {
                        const error = new Error('Cannot update a non draft newspaper order to be draft');
                        getErrorReporter().logAndCaptureError(ColumnService.OBITS, error, undefined, { newspaperOrderId: newspaperOrderModel.id });
                        throw error;
                    }
                    return newspaperOrderModel.ref.update(Object.assign(Object.assign({}, newspaperOrder), { status: NewspaperOrderStatus.DRAFT }));
                }
                if (validateCompleteNewspaperOrder(newspaperOrder)) {
                    return this.ctx
                        .orderNewspaperOrdersRef(this.ref)
                        .add(Object.assign(Object.assign({}, newspaperOrder), { orderVersion: version }));
                }
                // Log incomplete order
                getErrorReporter().logAndCaptureWarning('Incomplete newspaper order in updateModel', {
                    newspaperId: (_b = (_a = newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : '',
                    orderId: this.id
                });
            }));
            yield Promise.all(updatePromises);
            // Identify and soft-delete any newspaperOrderModels not present in newspaperOrdersFormData
            const softDeletePromises = newspaperOrderModels
                .filter(model => !newspaperOrders.some(newspaperOrder => {
                var _a;
                return ((_a = newspaperOrder === null || newspaperOrder === void 0 ? void 0 : newspaperOrder.newspaper) === null || _a === void 0 ? void 0 : _a.id) === model.modelData.newspaper.id &&
                    model.modelData.publishingMedium ===
                        newspaperOrder.publishingMedium;
            }))
                .map(model => model.ref.update({ status: NewspaperOrderStatus.DELETED }));
            yield Promise.all(softDeletePromises);
        });
    }
    getBillingName() {
        return __awaiter(this, void 0, void 0, function* () {
            if (isIndividualOrder(this.modelData)) {
                if (this.modelData.firstName && this.modelData.lastName) {
                    const name = `${this.modelData.firstName} ${this.modelData.lastName}`.trim();
                    return name;
                }
                return '';
            }
            const advertiserOrganizationSnap = yield getOrThrow(this.modelData.advertiserOrganization);
            return advertiserOrganizationSnap.data().name;
        });
    }
    /** Will return the first item on this list that's available:
     * - The contact email on the order
     * - The advertiser organization email
     * - The advertiser customer user's email
     * - The filing user's email
     */
    getBillingEmail() {
        return __awaiter(this, void 0, void 0, function* () {
            if (isIndividualOrder(this.modelData)) {
                return this.modelData.contactEmail;
            }
            const advertiserOrganizationSnap = yield getOrThrow(this.modelData.advertiserOrganization);
            const { email } = advertiserOrganizationSnap.data();
            if (email) {
                return email;
            }
            if (isPublisherAsAdvertiserOrder(this.modelData)) {
                const customerSnap = yield getOrThrow(this.modelData.advertiserCustomer);
                const userSnap = yield getOrThrow(customerSnap.data().user);
                return userSnap.data().email;
            }
            const userSnap = yield getOrThrow(this.modelData.user);
            return userSnap.data().email;
        });
    }
    getSaveCardName() {
        return __awaiter(this, void 0, void 0, function* () {
            if (isIndividualOrder(this.modelData)) {
                // TODO: To support individuals using saved cards, we need to check the advertiser too
                return wrapSuccess(null);
            }
            const { response: advertiserOrganization, error: getError } = yield this.getAdvertiserOrganization();
            if (getError) {
                return wrapError(getError);
            }
            if (!advertiserOrganization) {
                // TODO: To support individuals using saved cards, we need to check the advertiser too
                return wrapSuccess(null);
            }
            return wrapSuccess(advertiserOrganization.modelData.name);
        });
    }
    sumNewspaperOrderPricing(specifiedVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const newspaperOrders = yield this.getNewspaperOrders({
                includeDeleted: false,
                specifiedVersion
            });
            // Calculate new order totals
            const initialPricing = {
                subtotalInCents: 0,
                totalInCents: 0,
                convenienceFeeInCents: 0,
                totalDiscountInCents: 0
            };
            const result = newspaperOrders.reduce((acc, newspaperOrder) => {
                var _a;
                const { pricing } = newspaperOrder.modelData;
                if (!pricing) {
                    throw Error(`Missing pricing data for newspaperOrder ${newspaperOrder.id}`);
                }
                return {
                    subtotalInCents: acc.subtotalInCents + pricing.subtotalInCents,
                    totalInCents: acc.totalInCents + pricing.totalInCents,
                    convenienceFeeInCents: acc.convenienceFeeInCents + pricing.convenienceFeeInCents,
                    totalDiscountInCents: acc.totalDiscountInCents + ((_a = pricing.totalDiscountInCents) !== null && _a !== void 0 ? _a : 0)
                };
            }, initialPricing);
            return result;
        });
    }
    /**
     * Gets the top-level pricing only for the order
     */
    getOrderPricing(specifiedVersion = undefined) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: version, error: versionError } = this.getOrderVersion(specifiedVersion);
            if (versionError) {
                return wrapError(versionError);
            }
            const { response: orderDetail, error: detailError } = yield this.getOrCreateOrderDetail(version);
            if (detailError) {
                return wrapError(detailError);
            }
            const { pricing } = orderDetail.modelData;
            if (!pricing) {
                return wrapError(new NotFoundError('Missing pricing data for order'));
            }
            return wrapSuccess(pricing);
        });
    }
    /**
     * Gets all line items for all newspaper orders associated with this order
     *
     * @param distributeFee Whether to distribute the newspaper's processing
     * fee across that newspaper's line items. Used when displaying pricing to
     * the customer, not when storing pricing data on the invoice
     */
    getConsolidatedPricing({ distributeFee, version }) {
        return __awaiter(this, void 0, void 0, function* () {
            const newspaperOrders = yield this.getNewspaperOrders({
                includeDeleted: false,
                specifiedVersion: version
            });
            const consolidatedNewspaperLineItems = (yield Promise.all(newspaperOrders.map((newspaperOrder) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const { pricing } = newspaperOrder.modelData;
                if (!pricing) {
                    throw Error(`Missing pricing data for newspaperOrder ${newspaperOrder.id}`);
                }
                const { response: rate, error: rateError } = yield newspaperOrder.getRate();
                if (rateError) {
                    throw rateError;
                }
                // Orders are opinionated about how to distribute fees
                const distributeEnoticeFee = { evenly: true };
                const { convenienceFeeInCents, subtotalInCents } = pricing;
                const lineItems = distributeFee
                    ? getDistributedLineItems(pricing.lineItems, distributeEnoticeFee, rate.data(), {
                        feeToDistribute: convenienceFeeInCents,
                        expectedSubtotal: subtotalInCents
                    })
                    : pricing.lineItems;
                const { displayParams } = newspaperOrder.modelData;
                const columnInches = displayParams
                    ? getColumnInches(displayParams.height, displayParams.columns, (_a = rate.data().offset) !== null && _a !== void 0 ? _a : null)
                    : undefined;
                // Display params and column inches are used to render ad size information in the draft content step
                return {
                    newspaperId: newspaperOrder.modelData.newspaper.id,
                    newspaperOrderId: newspaperOrder.id,
                    displayParams: newspaperOrder.modelData.displayParams,
                    columnInches,
                    publishingMedium: newspaperOrder.modelData.publishingMedium,
                    lineItems
                };
            })))).flat();
            const newspaperOrderPricing = yield this.sumNewspaperOrderPricing(version);
            // Opting to handle this manually instead of bringing in the DBPricingObj
            if (distributeFee) {
                newspaperOrderPricing.subtotalInCents +=
                    newspaperOrderPricing.convenienceFeeInCents;
                newspaperOrderPricing.convenienceFeeInCents = 0;
            }
            const { response: orderPricing, error: orderPricingError } = yield this.getOrderPricing(version);
            if (orderPricingError) {
                throw orderPricingError;
            }
            return Object.assign(Object.assign(Object.assign({}, orderPricing), newspaperOrderPricing), { newspaperOrderPublishingDataGroup: consolidatedNewspaperLineItems });
        });
    }
    getOrderDetail(specifiedVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: version, error: versionError } = this.getOrderVersion(specifiedVersion);
            if (versionError) {
                return wrapError(versionError);
            }
            return this.orderDetailService.getByOrderAndVersion(this.ref, version);
        });
    }
    getOrCreateOrderDetail(specifiedVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: version, error: versionError } = this.getOrderVersion(specifiedVersion);
            if (versionError) {
                return wrapError(versionError);
            }
            return this.orderDetailService.getOrCreateByOrderAndVersion(this.ref, version);
        });
    }
    getInvoice(specifiedVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: version, error: versionError } = this.getOrderVersion(specifiedVersion);
            if (versionError) {
                return wrapError(versionError);
            }
            return this.invoiceService.getByOrderAndVersion(this.ref, version);
        });
    }
    getInvoiceMemo() {
        return __awaiter(this, void 0, void 0, function* () {
            const adResult = yield this.getAdByVersion();
            if (adResult.error) {
                return adResult;
            }
            const ad = adResult.response;
            if (isObituaryModel(ad)) {
                if (!ad.modelData.deceasedName) {
                    const errorMessage = 'No deceased name found on obituary';
                    const error = Error(errorMessage);
                    getErrorReporter().logAndCaptureError(ColumnService.OBITS, error, errorMessage, { orderId: this.id, adId: ad.id });
                    return wrapError(error);
                }
                return wrapSuccess(`Obituary for ${ad.modelData.deceasedName}`);
            }
            if (!ad.modelData.title) {
                const errorMessage = 'No title found on classified';
                const error = Error(errorMessage);
                getErrorReporter().logAndCaptureError(ColumnService.OBITS, error, errorMessage, { orderId: this.id, adId: ad.id });
                return wrapError(error);
            }
            return wrapSuccess(`Classified for ${ad.modelData.title}`);
        });
    }
    getAdByVersion(specifiedVersion = undefined) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: version, error: versionError } = this.getOrderVersion(specifiedVersion);
            if (versionError) {
                return wrapError(versionError);
            }
            const { response: ad, error: adError } = yield this.adService.getByOrderAndVersion(this.ref, version);
            if (adError) {
                const zeroAdsFound = adError.message.includes('instead got 0');
                const orderIsDraft = this.modelData.status === OrderStatus.DRAFT;
                if (zeroAdsFound && !orderIsDraft) {
                    getErrorReporter().logAndCaptureCriticalError(ColumnService.OBITS, adError, 'Non-draft order has no ad associated', {
                        orderId: this.id,
                        version: `${version}`,
                        orderType: this.modelData.product
                    });
                }
                return wrapError(adError);
            }
            return wrapSuccess(ad);
        });
    }
    /**
     * TODO(classifieds): Generalize this to `getAd()` and require the caller
     * to use new ad type guards to narrow the ad type if needed.  Also may
     * need to share universal props between ads
     */
    getObituary(specifiedVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: version, error: versionError } = this.getOrderVersion(specifiedVersion);
            if (versionError) {
                return wrapError(versionError);
            }
            const { response: obituary, error: obituaryError } = yield this.obituaryService.getByOrderAndVersion(this.ref, version);
            if (obituaryError) {
                return wrapError(obituaryError);
            }
            return wrapSuccess(obituary);
        });
    }
    getClassified(specifiedVersion) {
        return __awaiter(this, void 0, void 0, function* () {
            const { response: version, error: versionError } = this.getOrderVersion(specifiedVersion);
            if (versionError) {
                throw versionError;
            }
            const { response: classified, error: classifiedError } = yield this.classifiedService.getByOrderAndVersion(this.ref, version);
            if (classifiedError) {
                throw classifiedError;
            }
            return classified;
        });
    }
    get isObituaryOrder() {
        return this.modelData.product === Product.Obituary;
    }
    get isClassifiedOrder() {
        return this.modelData.product === Product.Classified;
    }
    /**
     * Status update helper that updates the status of all associated newspaper orders if required
     */
    statusUpdate(status, isVersionUpdate) {
        return __awaiter(this, void 0, void 0, function* () {
            let newspaperOrderStatus;
            const oldStatus = this.modelData.status;
            const isInitialPlacement = status === OrderStatus.PENDING && oldStatus === OrderStatus.DRAFT;
            const shouldResetConfirmationStatus = isVersionUpdate || isInitialPlacement;
            const isComplete = status === OrderStatus.COMPLETE;
            // Translate status change into potential newspaper order status change
            if (shouldResetConfirmationStatus) {
                newspaperOrderStatus = NewspaperOrderStatus.AWAITING_REVIEW;
            }
            if (status === OrderStatus.CANCELLED) {
                newspaperOrderStatus = NewspaperOrderStatus.CANCELLED;
            }
            const newspaperOrders = yield this.getNewspaperOrders();
            if (newspaperOrderStatus) {
                yield Promise.all(newspaperOrders.map((newspaperOrder) => __awaiter(this, void 0, void 0, function* () {
                    yield newspaperOrder.ref.update({
                        status: newspaperOrderStatus
                    });
                })));
            }
            if (isComplete) {
                const canMarkAsComplete = newspaperOrders.every(no => no.isComplete);
                if (!canMarkAsComplete) {
                    throw new Error('Cannot mark order as complete because not all newspaper orders are complete');
                }
            }
            yield this.update({ status });
        });
    }
    userInPublisherOrgCanCancelOrder(newspaper) {
        return __awaiter(this, void 0, void 0, function* () {
            let newspaperOrders;
            try {
                newspaperOrders = yield this.getNewspaperOrders();
            }
            catch (err) {
                return wrapError(err);
            }
            const relevantNewspaperOrderForPublisher = newspaperOrders.find(no => no.modelData.newspaper.id === newspaper.id);
            // If the order is not placed in the newspaper that the publisher is part of, don't allow cancellation
            if (!relevantNewspaperOrderForPublisher) {
                return wrapSuccess({
                    canCancel: false
                });
            }
            // If the order is only placed in the publisher's newspaper, allow cancellation whenever
            if (newspaperOrders.length === 1) {
                return wrapSuccess({
                    canCancel: true
                });
            }
            const relevantNewspaperOrderPubDate = getDateForDateStringInTimezone({
                dayString: relevantNewspaperOrderForPublisher.modelData.publishingDates[0],
                timezone: newspaper.modelData.iana_timezone
            });
            const relevantNewspaperOrderIsPastPublication = moment().isSameOrAfter(moment(relevantNewspaperOrderPubDate), 'day');
            // If the order has already published in the relevant newspaper, don't allow cancellation
            if (relevantNewspaperOrderIsPastPublication) {
                return wrapSuccess({
                    canCancel: false
                });
            }
            const otherNewspaperOrders = newspaperOrders.filter(no => no.id !== relevantNewspaperOrderForPublisher.id);
            const { response: orderIsPastDeadlineInAnotherNewspaper, error: orderIsPastDeadlineError } = yield this.newspaperOrderService.isAnyNewspaperOrderPastDeadline(otherNewspaperOrders);
            if (orderIsPastDeadlineError) {
                return wrapError(orderIsPastDeadlineError);
            }
            // If the order is past deadline in any other newspaper, don't allow cancellation
            if (orderIsPastDeadlineInAnotherNewspaper) {
                return wrapSuccess({
                    canCancel: false
                });
            }
            // If the order is not past deadline in any other newspaper, allow cancellation
            return wrapSuccess({
                canCancel: true
            });
        });
    }
    userInAdvertiserOrgCanCancelOrder(advertiserOrganization) {
        return __awaiter(this, void 0, void 0, function* () {
            // For the time being, only funeral homes can cancel orders
            if (advertiserOrganization.modelData.organizationType !==
                OrganizationType.funeral_home.value) {
                return wrapSuccess({
                    canCancel: false
                });
            }
            let newspaperOrders;
            try {
                newspaperOrders = yield this.getNewspaperOrders();
            }
            catch (err) {
                return wrapError(err);
            }
            const { response: orderIsPastDeadline, error: orderIsPastDeadlineError } = yield this.newspaperOrderService.isAnyNewspaperOrderPastDeadline(newspaperOrders);
            if (orderIsPastDeadlineError) {
                return wrapError(orderIsPastDeadlineError);
            }
            // If the order is past deadline in any newspaper, don't allow cancellation
            if (orderIsPastDeadline) {
                return wrapSuccess({
                    canCancel: false,
                    reason: 'This order cannot be cancelled because the ad deadline has passed.'
                });
            }
            // If the order is not past deadline in any newspaper, allow cancellation
            return wrapSuccess({
                canCancel: true
            });
        });
    }
    anonymousCanCancelOrder() {
        return __awaiter(this, void 0, void 0, function* () {
            let newspaperOrders;
            try {
                newspaperOrders = yield this.getNewspaperOrders();
            }
            catch (err) {
                return wrapError(err);
            }
            const { response: orderIsPastDeadline, error: orderIsPastDeadlineError } = yield this.newspaperOrderService.isAnyNewspaperOrderPastDeadline(newspaperOrders);
            if (orderIsPastDeadlineError) {
                return wrapError(orderIsPastDeadlineError);
            }
            // If the order is past deadline in any newspaper, don't allow cancellation
            if (orderIsPastDeadline) {
                return wrapSuccess({
                    canCancel: false,
                    reason: 'This order cannot be cancelled because the ad deadline has passed.'
                });
            }
            // If the order is not past deadline in any newspaper, allow cancellation
            return wrapSuccess({
                canCancel: true
            });
        });
    }
    // TODO: should this eventually be combined with the edit logic below?
    getUserCanCancelOrder(userInfo) {
        return __awaiter(this, void 0, void 0, function* () {
            // If the order is already cancelled, it can't be cancelled again
            if (this.modelData.status === OrderStatus.CANCELLED) {
                return wrapSuccess({
                    canCancel: false
                });
            }
            const { activeOrganization, isAnonymousUser } = userInfo;
            if (isAnonymousUser) {
                return this.anonymousCanCancelOrder();
            }
            if (!activeOrganization) {
                return wrapSuccess({
                    canCancel: false
                });
            }
            if (activeOrganization.isPublisherOrganization) {
                return this.userInPublisherOrgCanCancelOrder(activeOrganization);
            }
            return this.userInAdvertiserOrgCanCancelOrder(activeOrganization);
        });
    }
    getEditableDataForNewspapers(user) {
        return __awaiter(this, void 0, void 0, function* () {
            let newspaperOrders;
            try {
                newspaperOrders = yield this.getNewspaperOrders();
            }
            catch (err) {
                return wrapError(err);
            }
            const orderEditableData = {};
            const result = yield Promise.all(newspaperOrders.map((no) => __awaiter(this, void 0, void 0, function* () {
                const { response: editableData, error: editableDataError } = yield no.getEditableDataForUser(user);
                if (editableDataError) {
                    return wrapError(editableDataError);
                }
                orderEditableData[no.id] = editableData;
                return wrapSuccess(undefined);
            })));
            const errors = getErrors(result);
            if (errors.length) {
                return wrapError(errors[0]);
            }
            return wrapSuccess(orderEditableData);
        });
    }
    setActiveVersion(version, editedBy) {
        return __awaiter(this, void 0, void 0, function* () {
            const { activeVersion: currentActiveVersion } = this.modelData;
            const isVersionUpdate = version !== currentActiveVersion;
            if (isVersionUpdate) {
                const currentActiveNewspaperOrders = yield this.getNewspaperOrders();
                // soft-delete newspaper orders from outdated version
                const softDeletePromises = currentActiveNewspaperOrders.map(model => model.updateStatus(NewspaperOrderStatus.DELETED));
                yield Promise.all(softDeletePromises);
                // update active version on order
                yield this.update({ activeVersion: version });
                yield this.refreshData();
                yield this.createEvent(ORDER_EDITED, {
                    previousVersion: currentActiveVersion,
                    newVersion: version,
                    editedBy
                });
            }
            /**
             * Even if the status on this order is already pending
             * (e.g., if we went through the edit flow), we still want
             * to run this status update so that the new newspaper orders
             * are set to AWAITING_REVIEW
             */
            yield this.statusUpdate(OrderStatus.PENDING, true);
        });
    }
    generateNewVersionOfOrderItems() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { response: currentActiveOrderDetail, error: currentActiveOrderDetailError } = yield this.getOrderDetail();
                if (currentActiveOrderDetailError) {
                    return wrapError(currentActiveOrderDetailError);
                }
                if (!currentActiveOrderDetail) {
                    throw new Error('No order detail found');
                }
                const { response: currentActiveAd, error: currentActiveAdError } = yield this.getAdByVersion();
                if (currentActiveAdError) {
                    return wrapError(currentActiveAdError);
                }
                const currentActiveNewspaperOrders = yield this.getNewspaperOrders();
                const newVersion = this.ctx.timestamp().toMillis();
                if (isObituaryModel(currentActiveAd)) {
                    yield this.obituaryService.cloneForEditFlow(currentActiveAd, newVersion);
                }
                else if (isClassifiedModel(currentActiveAd)) {
                    yield this.classifiedService.cloneForEditFlow(currentActiveAd, newVersion);
                }
                else {
                    throw new Error('Unknown ad type');
                }
                yield this.orderDetailService.cloneForEditFlow(this.ref, currentActiveOrderDetail, newVersion);
                yield this.newspaperOrderService.cloneForEditFlow(this.ref, currentActiveNewspaperOrders, newVersion);
                return wrapSuccess({
                    version: newVersion
                });
            }
            catch (err) {
                return wrapError(err);
            }
        });
    }
    createEvent(type, data) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.eventService.createOrderEvent(this.ref, type, data);
        });
    }
}
